
.verification-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #212121; /* Черный фон страницы */
  color: white; /* Белый текст */
  font-family: 'Nanum Gothic', sans-serif; /* Подключенный шрифт */
}

.rounded-image {
  width: 100px; /* размер логотипа */
  height: 100px; /* размер логотипа */
  border-radius: 50%; /* делаем изображение круглым */
  margin-bottom: 20px; /* отступ снизу от логотипа */
}

.phone-number {
  margin-bottom: 8px; /* отступ снизу от номера телефона */
  font-size: 1.5em; /* размер шрифта номера телефона */
}

.info-text {
  margin-bottom: 20px; /* отступ снизу от информационного текста */
}

.code-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code-input {
  width: 300px; /* ширина поля ввода */
  padding: 10px; /* внутренний отступ поля ввода */
  margin-bottom: 20px; /* отступ снизу поля ввода */
  border: 1px solid #7289da; /* голубая граница */
  border-radius: 10px; /* скругленные углы */
  background: transparent; /* прозрачный фон поля ввода */
  color: white; /* белый текст ввода */
  font-size: 1em; /* размер шрифта ввода */
}

.code-input:focus {
  outline: none; /* убираем стандартный контур при фокусе */
  border-color: #fff; /* меняем цвет границы при фокусе */
}

.submit-button {
  padding: 10px 20px; /* внутренние отступы кнопки */
  border: none; /* без границ */
  border-radius: 5px; /* скругленные углы кнопки */
  background-color: #7289da; /* голубой фон кнопки */
  color: white; /* белый текст кнопки */
  font-size: 1em; /* размер шрифта кнопки */
  cursor: pointer; /* тип курсора */
}

.submit-button:hover {
  background-color: #5b6dad; /* темно-голубой фон кнопки при наведении */
}


.admin-panel {
  display: flex;
  height: 100vh;
  background-color: #212121;
  color: white;
  font-family: 'Nanum Gothic', sans-serif;
}



.sidebar-button {
  background-color: #7289da;
  color: white;
  padding: 10px;
  margin-bottom: 10px; /* Отступ между кнопками */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar-button:hover {
  background-color: #5b6dad;
}

