table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid white;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #505050;
}


.selector{
  background-color: #505050;
}


body{
  background-color: #212121;
}